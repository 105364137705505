import React from "react"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import get from "lodash.get"
import GatsbyImage from "gatsby-image"
import TextRenderer from "../TextRenderer"
import cn from "classnames"
export default function PrismicProjectSplitCard({ slice }) {
  const title = get(slice, "primary.title.raw")
  const text = get(slice, "primary.text.raw")
  const flip = get(slice, "primary.flip")
  const image_vertical_align = get(slice, "primary.image_vertical_align")
  const image = get(slice, "primary.image.fluid")
  return (
    <div
      className="  container split-card"
      css={css`
        & + .split-card {
          ${tw`-mt-10`}
        }

        .prose,
        .prose-xl {
          ul > li:before {
            ${tw`bg-text`}
          }
          li,
          p {
            font-family: "Georgia", serif;
          }
        }
      `}
    >
      {title && <TextRenderer raw={title} />}
      <div
        className={cn("row", {
          "items-center": !image_vertical_align,
        })}
      >
        <div className="col w-full md:w-1/2  ">
          {" "}
          <TextRenderer raw={text} />
        </div>
        <div
          className={cn("col w-full md:w-1/2 phone:order-last", {
            "order-first": flip,
          })}
        >
          {image && <GatsbyImage fluid={image} />}
        </div>
      </div>
    </div>
  )
}
