import { Link } from "gatsby"
import get from "lodash.get"
import React from "react"
import cn from "classnames"
import { css } from "@emotion/core"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Slider from "react-slick"
import AssetResolver from "../AssetResolver"

var settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.2,
  slidesToScroll: 1,
}
export default function PrismicProjectImage({ slice }) {
  const type = get(slice, "primary.type")
  const caption = get(slice, "primary.caption")
  const background = get(slice, "primary.background")
  const image = get(slice, "primary.image.fluid")
  const url = get(slice, "primary.image.url")
  const alt = get(slice, "primary.image.alt")
  const bgvars = {
    Light: "bg-light",
    Primary: "bg-primary",
    Charcoal: "bg-charcoal",
    Dusk: "bg-dusk",
    Slate: "bg-slate",
  }
  switch (type) {
    case "Single Full Width":
      return (
        <div className="container my-10">
          <AssetResolver fluid={image} alt={alt} url={url} />
          {caption && (
            <div className="text-dusk text-center mt-2">{caption}</div>
          )}
        </div>
      )
    case "Single Full Screen":
      return (
        <div>
          <AssetResolver fluid={image} alt={alt} />
          {caption && (
            <div className="text-dusk text-center mt-2 mb-10">{caption}</div>
          )}
        </div>
      )
    case "Images Grid":
      return (
        <div className={cn(bgvars[background])}>
          <div
            className="container "
            css={css`
              @media (min-width: 1024px) {
                max-width: 1135px;
              }
            `}
          >
            <Grid items={slice.items} />
          </div>
        </div>
      )

    default:
      return null
  }
}

function Grid({ items }) {
  return (
    <>
      {" "}
      <div className="phone:hidden grid gap-6 md:grid-cols-3 grid-cols-2">
        {items &&
          items.map((item, index) => {
            const image = get(item, "images.fluid")
            const url = get(item, "images.url")
            const alt = get(item, "images.alt")
            return (
              <div key={index}>
                <AssetResolver {...{ fluid: image, url, alt }} />
              </div>
            )
          })}
      </div>
      <div className="phone:block hidden">
        <Slider {...settings}>
          {items &&
            items.map((item, index) => {
              const image = get(item, "images.fluid")
              const url = get(item, "images.url")
              const alt = get(item, "images.alt")
              return (
                <div key={index} className="pr-4">
                  <AssetResolver {...{ fluid: image, url, alt }} />
                </div>
              )
            })}
        </Slider>
      </div>
    </>
  )
}
