import get from "lodash.get"
import React from "react"
import TextRenderer from "../TextRenderer"
import cn from "classnames"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
export default function PrismicProjectText({ slice }) {
  const raw = get(slice, "primary.text.raw", "")
  const alignment = get(slice, "primary.alignment", false)

  const quote_style = get(slice, "primary.quote_style")
  const background = get(slice, "primary.background")
  const bgvars = {
    Light: "bg-light",
    Primary: "bg-primary",
    Charcoal: "bg-charcoal",
    Dusk: "bg-dusk",
    Slate: "bg-slate",
  }
  return (
    <section className={cn(bgvars[background])}>
      <div className={cn("container")}>
        <div
          css={css`
            .prose,
            .prose-xl {
              p {
                font-family: "Georgia";
                ${quote_style && tw`text-xl text-dusk italic`}
              }
              .primary-button,
              .secondary-button {
                ${tw`font-sans`}
              }
              ul > li:before {
                ${tw`bg-text`}
              }
              li {
                font-family: "Georgia";
              }
            }
          `}
          className={cn({
            "text-center": alignment,
          })}
        >
          <TextRenderer raw={raw} />
        </div>
      </div>
    </section>
  )
}
