import React from "react"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import get from "lodash.get"
import GatsbyImage from "gatsby-image"
import TextRenderer from "../TextRenderer"
import cn from "classnames"
import {
  Vertical,
  VerticalFlip,
  Horizontal,
  HorizontalFlip,
} from "../Connectors"
export default function PrismicProjectStep({ slice }) {
  const title = get(slice, "primary.title.raw")
  const text = get(slice, "primary.text.raw")
  const flip = get(slice, "primary.flip")
  const image = get(slice, "primary.image.fluid")
  const background = get(slice, "primary.background")
  const number = get(slice, "primary.number")
  const label = get(slice, "primary.label")
  const bgvars = {
    Light: "bg-light",
    Primary: "bg-primary",
    Charcoal: "bg-charcoal",
    Dusk: "bg-dusk",
    Slate: "bg-slate",
  }
  return (
    <>
      <div
        className={cn("step", bgvars[background], {
          flip,
        })}
      >
        <Vertical height={86} />
        <Horizontal />
        <HorizontalFlip />
        <VerticalFlip height={86} />
        <div
          className={cn("  container split-card")}
          css={css`
            & + .split-card {
              ${tw`-mt-10`}
            }
            .prose,
            .prose-xl {
              ul > li:before {
                ${tw`bg-text`}
              }
              li,
              p {
                font-family: "Georgia", serif;
              }
            }
          `}
        >
          {title && <TextRenderer raw={title} />}
          <div
            className={cn("row", {
              // "items-center": !image_vertical_align,
            })}
          >
            <div className="col w-full md:w-1/2  ">
              <div className="mb-3">
                <span
                  css={css`
                    font-family: "SFProDisplay";
                    font-size: 64px;

                    line-height: 1;
                    letter-spacing: -0.27px;
                    color: #e4e4e4;
                    display: inline-block;
                    margin-right: 16px;
                  `}
                >
                  {number < 10 && "0"}
                  {number}
                </span>
                <span
                  css={css`
                    font-family: "SFProText";
                    font-size: 16px;
                    font-weight: bold;

                    line-height: 1.5;
                    letter-spacing: -0.07px;
                    text-align: justify;
                    color: #e4e4e4;
                  `}
                >
                  {label}
                </span>
              </div>
              <TextRenderer raw={text} />
            </div>
            <div
              className={cn("col w-full md:w-1/2 phone:order-last", {
                "order-first": flip,
              })}
            >
              {image && <GatsbyImage fluid={image} />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
