import React from "react"
import { graphql } from "gatsby"
import PrismicProjectHeroImage from "../components/slices/PrismicProjectHeroImage"
import PrismicProjectIntroText from "../components/slices/PrismicProjectIntroText"
import Footer from "../components/Footer"
import get from "lodash.get"
import PageAnimation from "../components/PageAnimation"
import { css } from "@emotion/core"
import PrismicProjectText from "../components/slices/PrismicProjectText"
import PrismicProjectImage from "../components/slices/PrismicProjectImage"
import PrismicProjectSplitCard from "../components/slices/PrismicProjectSplitCard"
import PrismicProjectStep from "../components/slices/PrismicProjectStep"
import { Spacer } from "./Spacer"
import CloseButton from "../components/CloseButton"
import Seo from "../components/SEO"
export default function Project({ data, location }) {
  const slices = get(data, "prismicProject.data.body")
  return (
    <>
      <Seo data={get(data, "seo.data")} />
      <PageAnimation hideHeader={true} location={location}>
        <div>
          <div className="relative ">
            <PrismicProjectHeroImage
              image={get(data, "prismicProject.data.hero_image")}
            />
            <PrismicProjectIntroText
              text={get(data, "prismicProject.data.intro_text.raw")}
              start_date={get(data, "prismicProject.data.start_date")}
              end_date={
                get(data, "prismicProject.data.end_date") ||
                get(data, "prismicProject.data.date")
              }
            />
          </div>
          <div className="project-page">
            {slices &&
              slices.map((slice, index) => {
                switch (slice.__typename) {
                  case "PrismicProjectBodyText":
                    return (
                      <Spacer slice={slice} key={index}>
                        <PrismicProjectText slice={slice} />
                      </Spacer>
                    )
                  case "PrismicProjectBodyImage":
                    return (
                      <Spacer slice={slice} key={index}>
                        <PrismicProjectImage slice={slice} />
                      </Spacer>
                    )
                  case "PrismicProjectBodySplitCard":
                    return (
                      <Spacer slice={slice} key={index}>
                        <PrismicProjectSplitCard slice={slice} />
                      </Spacer>
                    )
                  case "PrismicProjectBodySteps":
                    return (
                      <Spacer slice={slice} key={index}>
                        <PrismicProjectStep slice={slice} />
                      </Spacer>
                    )

                  default:
                    break
                }
              })}
          </div>
        </div>
      </PageAnimation>
    </>
  )
}
export const query = graphql`
  query($uid: String) {
    seo: prismicProject(uid: { eq: $uid }) {
      data {
        metatitle
        metakeywords
        metadescription
        metaimage {
          url
        }
      }
    }
    prismicProject(uid: { eq: $uid }) {
      id
      data {
        hero_image {
          fluid(maxWidth: 2500, imgixParams: { q: 100 }) {
            ...GatsbyPrismicImageFluid
          }
        }
        intro_text {
          raw
        }
        date(formatString: "MMM Do, YYYY")
        start_date(formatString: "Do MMM YYYY")
        end_date(formatString: "Do MMM YYYY")
        body {
          ... on PrismicProjectBodyImage {
            id
            items {
              images {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
                dimensions {
                  width
                }
                alt
                url
              }
            }
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
                url
                alt
              }
              background
              caption
              type
            }
          }
          ... on PrismicProjectBodyText {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              alignment
              background

              quote_style
              text {
                raw
              }
            }
          }
          ... on PrismicProjectBodySplitCard {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              flip
              text {
                raw
              }
              title: title1 {
                raw
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
                url
              }
            }
          }
          ... on PrismicProjectBodySteps {
            id
            primary {
              desktop_space_bottom
              desktop_space_top
              mobile_space_bottom
              mobile_space_top
              background
              flip
              label
              number
              text {
                raw
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
