import get from "lodash.get"
import React from "react"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import TextRenderer from "../TextRenderer"
export default function PrismicProjectIntroText({
  text,

  start_date,
  end_date,
}) {
  return (
    <section
      className="container phone:mt-16 mt-24"
      css={css`
        .prose h1,
        .prose-xl h1,
        .prose h1 > *,
        .prose-xl h1 > *,
        .prose h2 > *,
        .prose-xl h2 > *,
        .prose h2,
        .prose-xl h2 {
          font-family: Georgia, "Times New Roman", Times, serif !important;
        }
        .prose,
        .prose-xl {
          ul,
          p {
            font-family: Georgia, "Times New Roman", Times, serif !important;
            ${tw`text-dusk text-2xl`}
          }
        }
      `}
    >
      <TextRenderer raw={text} />
      <div className="text-dusk mt-4">
        {start_date && `${start_date} - `}
        {end_date}
      </div>
    </section>
  )
}
