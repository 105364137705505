import { Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import get from "lodash.get"
import React from "react"
export default function PrismicProjectHeroImage({ image }) {
  const fluid = get(image, "fluid")
  return (
    fluid && (
      <div className="relative">
        <GatsbyImage fluid={fluid} />
      </div>
    )
  )
}
