import GatsbyImage from "gatsby-image"
import React from "react"

export default function AssetResolver({ fluid, url, alt }) {
  if (fluid) {
    return <GatsbyImage fluid={fluid} alt={alt} />
  } else {
    return null
  }
}
