import React from "react"
import cn from "classnames"
import { css } from "@emotion/core"
export function Vertical({ height, className, ...rest }) {
  const cr = 6
  const vBWidth = 14
  const vBHeight = height || 80
  const pathHeight = vBHeight - 2 * vBWidth
  return (
    <div
      className={cn("container connector vertical relative", className)}
      {...rest}
    >
      <svg
        width={vBWidth + 32}
        height={height}
        viewBox={`0 0 ${vBWidth} ${vBHeight}`}
        className="absolute -top-10 left-3 md:left-8"
      >
        <circle
          cx={vBWidth / 2}
          cy={vBWidth / 2}
          r={cr}
          stroke="#e4e4e4"
          strokeWidth="2"
          fill="transparent"
        />
        <path
          d={`M${vBWidth / 2},${vBWidth + 2}v0v${pathHeight}`}
          strokeOpacity={1}
          stroke="#e4e4e4"
          strokeWidth={1.5}
          strokeDasharray={"4,4"}
        />
        <circle
          cx={vBWidth / 2}
          cy={vBHeight - vBWidth / 2}
          r={cr}
          stroke="#e4e4e4"
          strokeWidth="2"
          fill="transparent"
        />
      </svg>
    </div>
  )
}
export function VerticalFlip({ height, className, ...rest }) {
  const cr = 6
  const vBWidth = 14
  const vBHeight = height || 80
  const pathHeight = vBHeight - 2 * vBWidth
  return (
    <div
      className={cn("container connector vertical-flip relative", className)}
      {...rest}
    >
      <svg
        width={vBWidth + 32}
        height={height}
        viewBox={`0 0 ${vBWidth} ${vBHeight}`}
        className="absolute -top-10 right-8"
      >
        <circle
          cx={vBWidth / 2}
          cy={vBWidth / 2}
          r={cr}
          stroke="#e4e4e4"
          strokeWidth="2"
          fill="transparent"
        />
        <path
          d={`M${vBWidth / 2},${vBWidth + 2}v0v${pathHeight}`}
          strokeOpacity={1}
          stroke="#e4e4e4"
          strokeWidth={1.5}
          strokeDasharray={"4,4"}
        />
        <circle
          cx={vBWidth / 2}
          cy={vBHeight - vBWidth / 2}
          r={cr}
          stroke="#e4e4e4"
          strokeWidth="2"
          fill="transparent"
        />
      </svg>
    </div>
  )
}
export function Horizontal({ width, height, className, ...rest }) {
  return (
    <div
      className={cn("container connector horizontal relative  ", className)}
      {...rest}
    >
      <HBase width="515" className="-top-16 tablet:hidden" />
      <HBase width="384" className="-top-12 phone:hidden lg:hidden" />
    </div>
  )
}
export function HorizontalFlip({ width, height, className, ...rest }) {
  return (
    <div
      className={cn(
        "container connector horizontal-flip relative  ",
        className
      )}
      {...rest}
    >
      <HFlipBase width="515" className="-top-16 tablet:hidden" />
      <HFlipBase width="384" className="-top-12 phone:hidden lg:hidden" />
    </div>
  )
}

function HBase({ width = 515, vBHeight = 100, className }) {
  const vBWidth = width

  return (
    <svg
      viewBox={`0 0 ${width} 100`}
      className={cn("absolute  left-10", className)}
      css={css`
        width: ${width}px;
      `}
    >
      <circle
        cx={8}
        cy={8}
        r={6}
        stroke="#e4e4e4"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        d={`
          M 8 20

          L 8  ${vBHeight / 2 - 10}
          C 8 ${vBHeight / 2}, 8 ${vBHeight / 2}, 18  ${vBHeight / 2}
          H ${vBHeight / 2} ${vBWidth - 18}
          C ${vBWidth - 8} ${vBHeight / 2},${vBWidth - 8} ${vBHeight / 2}, ${
          vBWidth - 8
        } ${vBHeight / 2 + 10}
          V ${vBHeight - 20}`}
        strokeOpacity={1}
        fill="transparent"
        stroke="#e4e4e4"
        strokeWidth={1.5}
        strokeDasharray={"4,4"}
      />
      <circle
        cx={vBWidth - 8}
        cy={vBHeight - 8}
        r={6}
        stroke="#e4e4e4"
        strokeWidth="2"
        fill="transparent"
      />
    </svg>
  )
}

function HFlipBase({ width = 515, height = 100, className }) {
  const vBWidth = width

  return (
    <svg
      viewBox={`0 0 ${width} 100`}
      className={cn("absolute  left-10", className)}
      css={css`
        width: ${width}px;
      `}
    >
      <circle
        cx={width - 8}
        cy={8}
        r={6}
        stroke="#e4e4e4"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        d={`
          M ${width - 8} 20
          V ${height / 2 - 10}
          C ${width - 8} ${height / 2},${width - 8} ${height / 2}, ${
          width - 18
        } ${height / 2}
          H 18
          C 8 ${height / 2}, 8 ${height / 2}, 8  ${height / 2 + 10}

          V ${height - 20}`}
        strokeOpacity={1}
        fill="transparent"
        stroke="#e4e4e4"
        strokeWidth={1.5}
        strokeDasharray={"4,4"}
      />{" "}
      <circle
        cx={8}
        cy={height - 8}
        r={6}
        stroke="#e4e4e4"
        strokeWidth="2"
        fill="transparent"
      />
    </svg>
  )
}
